import { FunctionalComponent, h } from 'preact';
import { Link } from 'preact-router/match';
import { clearTokens } from '../../services/token-service';
import style from './style.css';

interface IHeaderProps {
    logout: () => void;
}

const Header: FunctionalComponent<IHeaderProps> = (props: IHeaderProps) => {

    const handleLogout = props.logout;

    return (
        <header class={style.header}>
            <h1>Support Tool</h1>
            <nav>
                {/* <Link activeClassName={style.active} href="/">
                    Dashboard
                </Link>
                <Link activeClassName={style.active} href="/clients">
                    Clients
                </Link>
                <Link activeClassName={style.active} href="/accounts">
                    Accounts
                </Link> */}
                <button class="text-white mr-4 ml-8 py-4" onClick={handleLogout}>
                    logout
                </button>
            </nav>
        </header>
    );
};

export default Header;
